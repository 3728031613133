/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { useStores } from '@stores';
import { observer } from 'mobx-react';
import { Box, Spinner } from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
// @ts-ignore
import featuredImage from '../../resources/images/asukasdemokratia.jpg';
interface IResidentActivityResidentCommitteePage {
  data: IMarkdownData;
}

const ResidentActivityResidentCommitteePage: FC<IResidentActivityResidentCommitteePage> = observer(() => {
  const { agreementStore: { selectedAgreement, state } }: { agreementStore: IAgreementStore } = useStores();
  const isLoading = state === 'Loading';
  const contacts = selectedAgreement?.estate?.contacts.filter((contact) => {
    return contact.contact_type === 'Asukastoimikunnan jäsen' || contact.contact_type === 'Asukastoimikunnan puheenjohtaja';
  });
  return (
    <ContentPageLayout featuredImageFile={featuredImage} slug="resident-committee" >
      {isLoading && <Spinner color="mainBlue" />}
      {!isLoading && selectedAgreement?.estate?.contacts && (
        <ul>
          {contacts?.map((contact: { name: string, contact_type: any }) => <li>{contact.name}, {contact.contact_type}</li>)}
        </ul>
      )}
      {!isLoading && !selectedAgreement?.estate?.contacts && (
        <Box>
          <M id="pages.residentActivity.noResidentCommittee" />
        </Box>
      )}
    </ContentPageLayout>
  );
});


export default ResidentActivityResidentCommitteePage;
